import React from 'react'
import { Container, Box, Typography } from '@material-ui/core'
import { useStyles } from './style'

const PrivacyPolicyLayout = () => {
    const classes = useStyles()

    return (
        <Box id='privacy' position='relative' paddingTop={'10rem'} className={classes.root}>
            <Box className={classes.hero} paddingBottom="3rem">
                <Typography variant="h2" align="center" gutterBottom>
                    Privacy Policy
                </Typography>
            </Box>
            <Box className={classes.body}>
                <Container maxWidth={'md'}>



                <p><strong>Introduction</strong></p>
                    <p>This Privacy Policy (&ldquo;the Policy&rdquo;) governs your use of our Education Financing Solution Platform (&rdquo;Bursery&rdquo;),  (&ldquo;the Platform&rdquo;) developed by Edutech Advanced Business Technologies Limited (&ldquo;We/Our/Us&rdquo;) and your rights regarding our collection, use, storage protection of your Personal Data.&nbsp;</p>
                    <p><strong>What Personal Data we collect</strong></p>
                    <p>Personal Data means any information or data about an individual from which that person can be identified. It does not include data or information where the identity has been removed.</p>
                    <p>We may collect and process the following Personal Data through your use of the Platform or any of our Services</p>
                    <ul>
                    <li>Records of any surveys that we may ask you to complete that we use for research purposes</li>
                    <li>Student Information including name, date of birth, age, phone number, email address, age, citizenship, religion, sex, next of kin details and any other information required to process your information as a student on the Platform</li>
                    <li>Information provided in connection with an application for a student loan via Educollect, including credit checks, passport photographs, information relating to your income, expenses, assets, debts, account balances or payment history</li>
                    </ul>
                    <ul>
                    <li>Any other Personally Identifiable Information that you choose to provide to EDUTECH or that you consent to us collecting.</li>
                    </ul>
                    <p>Personal Data may be given to us directly by you or by people or companies authorized by you to act on your behalf. We may also collect Personal Data about you from third parties in connection with our Services (for example, a credit reporting agency). No Personal Data about you will be collected without your consent for us to do so.</p>
                    <p>Where we need to collect Personal Data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to provide the Services envisaged or requested.</p>
                    <p><strong>Consent</strong></p>
                    <p>If you choose to use the Platform, you agree to the collection and use of your Personal Data in accordance with this Policy. The personal information collected may be used to improve our customer experience on the Platform. We will not use or share your personal information with anyone except as provided under this Policy.</p>
                    <p><strong>Collection of Personal Data and Use</strong></p>
                    <p>We use Personal Data held about you in the following ways;</p>
                    <ul>
                    <li>to process student applications with third party (lenders) to finance their education via the Platform and to assess lending risks</li>
                    <li>to process student information in furtherance of our existing obligations under Contracts with Educational Institutions</li>
                    <li>to undertake any obligation arising from and exercise our rights under any agreement between you and us</li>
                    <li>to notify you about changes to our services</li>
                    <li>to process and assess your application for employment with us</li>
                    <li>for statistical analysis and market research</li>
                    <li>to develop and improve our services</li>
                    <li>to update our records</li>
                    <li>to satisfy a legitimate interest (which is not overridden by your data protection interest).</li>
                    <li>to comply with a legal or regulatory obligation</li>
                    <li>to manage risk, detect or prevent or remediate fraud or other potentially prohibited or illegal services.</li>
                    <li>for any other purpose you have specifically consented to</li>
                    </ul>
                    <p><strong>How long We Store Your Personal Data</strong></p>
                    <p>We will retain your Personal Data for as long as you remain an active user of the Platform unless you request we remove your Personal Data as provided under this Policy. Please note that your Personal Data may be retained for a longer period notwithstanding your request to remove your Personal Data, where there is a legal requirement to do so.</p>
                    <p>Where your Personal Data has been shared with a lender on the Platform whose funding you have obtained for the financing of an education program via the Platform, the Personal Information held by such a lender may also be retained thereby and shall be governed by the lender’s applicable policy on retention of user data. </p>
                    <p><strong>Link to Other Websites</strong></p>
                    <p>Please note that the services provided via the Platform may contain links to other websites not operated or controlled by us. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from any of our services to another website, our Privacy and Cookies Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our site, is subject to the website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you. </p>
                    <p><strong> Your rights with respect to your Personal Data</strong></p>
                    <p>You have the following rights in relation to your Personal Data held with us;</p>
                    <ul>
                    <li>To access the Personal Data held by us about you</li>
                    <li>To have your Personal Data corrected e.g If it is incomplete or incorrect.</li>
                    <li>To opt out of receiving marketing communications at anytime</li>
                    <li>To restrict or object to the processing of Personal Data or request erasing Personal Data (subject to applicable law)</li>
                    <li>To receive a copy of the Personal Data which you have provided to us, in a structured, commonly used and machine-readable format (in certain circumstances and subject to applicable law) </li>
                    <li>Where you have provided Personal Data voluntarily, or otherwise consented to its use, the right to withdraw your consent.</li>
                    <li>The right to complain to a data protection authority (see section on &ldquo;Complaints&rdquo;)</li>
                    </ul>
                    <p><strong>How your Personal Data is stored?</strong></p>
                    <p>We store and process your Personal Data on our computers in Lagos, Nigeria, Africa and on a cloud based network hosted in the Republic of Ireland region of Amazon Web Services. Data protection laws vary among countries, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this Policy. You have a right (upon your request) to be informed of the appropriate safeguards for data protection in the foreign country.</p>
                    <p>We protect your Personal Data using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.</p>
                    <p>In the event of an actual or suspected breach of your Personal Data, we will use best effort to remedy the breach within one (1) month from the date we report the breach to you.</p>
                    <p><strong>Who we share your Personal Data with?</strong></p>
                    <p>You agree that we may share your Personal Data with third parties as described in the table below.</p>
                    <ul>
                    <li>Affiliates &ndash; Our Affiliates may access your Personal Data to help us develop, maintain and provide the services via the Platform</li>
                    </ul>
                    <ul>
                    <li>Service Providers &ndash; Our Service Providers provide us support for our services, including website and maintenance development, hosting, maintenance, backup, storage, virtual infrastructure, payment processing, third party lenders and other services which may require them to access or use Personal Data about you</li>
                    </ul>
                    <ul>
                    <li>Professional Advisers &ndash; Our lawyers, accountants, auditors may need to review your Personal Data to provide consultancy, compliance, legal, accounting, and similar services </li>
                    </ul>
                    <ul>
                    <li>Legal / Regulatory Authority &ndash; We may disclose your Personal Data if we believe it is reasonably necessary to comply with a law, regulation, order, subpoena or audit or to protect the safety of any person, to address fraud, security or technical issues</li>
                    </ul>
                    <ul>
                    <li>Advertisers &ndash; We may allow third parties including ad-servers or ad-networks, to serve advertisement on the Platform, and such third parties may be provided with access to your Personal Data to provide advertising tailored to your interest.</li>
                    </ul>
                    <ul>
                    <li>Application Programme Interface (API) Users &ndash; A limited number of partners may have API access to portions of the Platform e.g Crednet Technologies Limited, Obafemi Awolwo University, Ile-Ife, Osun State, Ahmadu Bello University, Zaria</li>
                    </ul>
                    <ul>
                    <li>Other parties &ndash; Please note that top level employees, authorized employees required to have access to the Platform in the course of their employment with us and investors, may have access to your Personal Data. In the event of a corporate sale, merger, reorganization, dissolution or similar event, we may also transfer your Personal Data as part of the transferred asset without your consent or notice to you. </li>
                    </ul>
                    <p><strong>Security</strong></p>
                    <p>We value your trust in providing us with your Personal Data in connection with your use of the Platform and shall endeavor to use commercially acceptable means of protecting it. However, we do not guarantee its absolute security.</p>
                    <p><strong>Children&rsquo;s Privacy</strong></p>
                    <p>We do not use the Platform to knowingly solicit data from children or individuals under the age of Fifteen (15). Where a parent or guardian discovers that a child or individual under the age of Fifteen (15) has provided us with his/her Personal Data without their consent, he or she should contact us at <a href="mailto:support@bursery.com"><em>support@bursery.com</em></a> and we shall immediately delete the user&rsquo;s account from our servers.</p>
                    <p><strong>Changes to This Policy</strong></p>
                    <p>We may update this Policy from time to time without any prior notice to you or consent. Thus, you are advised to consult this Platform periodically for any changes. We will notify you of any changes by posting the revised Policy on this Platform. Please note that changes shall be effective immediately after they are updated on this Platform.</p>
                    <p><strong>Complaints</strong></p>
                    <p>If you are concerned about an alleged breach of privacy law or any other regulation by us, you can contact the Data Protection Officer via  {<em>dpo@edutechng.com</em>}. The Data Protection Officer will investigate your complaint and provide information about how your complaint is handled.</p>
                    <p>Please be informed that where your complaints are not satisfactorily addressed, you may lodge a complaint with a relevant data protection authority.&nbsp;</p>
                    <p><strong>Date of last revision: 30<sup>th</sup> October 2020</strong></p>





                </Container>
            </Box>
        </Box>
    )
}

export default PrivacyPolicyLayout
