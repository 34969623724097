import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F2F8FF',
    },
    body: {
        backgroundColor: '#FFFFFF',
        padding: "2.5rem 0",
        '& p, li': {
            fontWeight: 400,
            fontSize: `1rem` //16
        }
    }
}));

export { useStyles }