import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import PrivacyPolicyLayout from '../components/Layout/PrivacyPolicyLayout'

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Home" />
            <Layout withFooter withHeader>
                <Box>
                    <PrivacyPolicyLayout />
                </Box>
            </Layout>
        </>
    )
}

export default PrivacyPolicy
